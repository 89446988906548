import Grid from '@mui/material/Grid'
import { useInView } from 'react-intersection-observer'
import {
	Container,
	Title,
	Description,
	CtaContainer,
	Link,
	HeroStyled,
	Subtitle,
	Button,
} from '@/components/ui/organisms/sticky-banner/sticky-banner.styled'

const StickyBanner = ({
	aboveTheFold,
	hasVideo,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	content,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	const noFadeInCssClass = props.className.indexOf('no-fade-in') > -1

	const [containerRef, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	})

	const showIn = noFadeInCssClass || inView

	return (
		<HeroStyled
			overlayComponent={
				<Container ref={containerRef}>
					{subtitle && (
						<Subtitle show={showIn} variant="subheadline1" tabIndex="0" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					{title && (
						<Title show={showIn} variant="headlineMedium" tabIndex="0" {...titleProps}>
							{title}
						</Title>
					)}
					{content && (
						<Description show={showIn} variant="subheadline1" tabIndex="0" {...descriptionProps}>
							{content}
						</Description>
					)}
					{cta && cta?.length > 0 && (
						<CtaContainer
							show={showIn}
							display="inline-flex"
							justifyContent={props.horizontalAlign}
							hasSiblings={!!content || !!title}
							{...ctaProps}
						>
							{cta.map(({ label, url, variant, ...rest }, key) => (
								<Grid item key={key}>
									<Button tabIndex={0} label={label} href={url} variant={variant} {...rest}>
										{label}
									</Button>
								</Grid>
							))}
						</CtaContainer>
					)}
					{additionalContent}
				</Container>
			}
			backgroundComponent={
				<Link
					className={hasVideo && aboveTheFold ? 'video-aboveTheFold' : null}
					href={fullUrl}
					onClick={fullOnClick}
					sx={{
						cursor: fullUrl || fullOnClick ? 'pointer' : 'auto',
						height: aboveTheFold ? '100%' : undefined,
						display: hasVideo && aboveTheFold ? 'flex' : undefined,
						overflow: hasVideo && aboveTheFold ? 'hidden' : undefined,
						padding: hasVideo && aboveTheFold ? 0 : undefined,
						position: hasVideo && aboveTheFold ? 'relative' : undefined,
						zIndex: hasVideo && aboveTheFold ? 0 : undefined,
					}}
					tabIndex={0}
				>
					{backgroundComponent}
				</Link>
			}
			{...props}
		/>
	)
}

export default StickyBanner
